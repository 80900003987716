// initial state
import { showNotification } from '../../sendNotification'
const state = {
  message_types: [],
  messages: [],
  sorted_messages: [],
  hash:'',
}

// getters
const getters = {
  getMessageTypes: (state) => {
    console.log(state.message_types);
    return state.message_types.filter(m => m.type === 2)
  },
  getMessages(state){
    return state.sorted_messages
     
  },
  getHash(state){
    return state.hash
  },
  getUnread(state){
    return state.messages.filter(m => m.status === 1)
  },
  message_by_id: (state) => (id) => {
    if(!state.messages.length||id==0){
      return {}
    }
    const message = state.messages.find(m => m.id === parseInt(id))
    return message
  }
}

// actions
const actions = {
  getAllMessages ({ dispatch, rootGetters }) {
    let user = rootGetters['user/getUser']
    dispatch('emit', {
      end: "messages/setMessages",
      call: "getMessages",
      parameters:{
        user_id: user.id,//todo ovde treba po firmi ili poslovnici, da user koji je za samo jednu poslovnicu vidi samo svoje usere, a onaj koji je glavni za celu firmu vidi sve usere
      },
      response:"[]"
    }, { root: true })
  },
  setMessages ({dispatch, commit}, data) {
    let res = JSON.parse(data.response);
    if(typeof res.logout != 'undefined' && res.logout==1){
      window.onbeforeunload = function () {
      }
      dispatch('user/logout', null, { root: true })
    } else if(typeof res.status == 'undefined' && res.status != 401){
      commit('setMessages', res.messages)
      commit('sortMessages')
    }else{
      commit('setMessages', [])
    }
  },
  getUnseenMessages ({ dispatch, rootGetters, getters }, payload) {
    let unread = getters['getUnread']
    let user = rootGetters['user/getUser']
    dispatch('emit', {
      end: "messages/setUnseenMessages",
      call: "a_getMessages",
      parameters:{
        hash: payload.hash,
        unread: unread.length,
        user_id: user.id,//todo ovde treba po firmi ili poslovnici, da user koji je za samo jednu poslovnicu vidi samo svoje usere, a onaj koji je glavni za celu firmu vidi sve usere
      },
      response:"response"
    }, { root: true })
  },
  setUnseenMessages ({dispatch, commit, getters}, data) {
    let hash = getters['getHash']
    let res =  JSON.parse(data.response)
    if(typeof res.logout != 'undefined' && res.logout==1){
      window.onbeforeunload = function () {
      }
      dispatch('user/logout', null, { root: true })
    } else {
      if(data.parameters.hash === hash){
        if(typeof res.status == 'undefined'){
          if(parseInt(res.messages.filter(m => m.status === 1).length) > parseInt((typeof data.parameters.unread != 'undefined')?data.parameters.unread:0)){
            var storedPermission = localStorage.getItem('notificationPermission');
            console.log('storedPermission',storedPermission);
            if(storedPermission === null){
              dispatch('notification_modal', { modal_open: true, title: 'Nove poruke', text: 'Imate nove nepročitane poruke.' }, { root: true })
            }else if(storedPermission === 'granted'){
              showNotification('Imate nove nepročitane poruke.')
            }else if(storedPermission === 'denied') {
              commit('message_modal', { title: 'Nove poruke', text: 'Imate nove nepročitane poruke.' }, { root: true })
              new Audio(require('@/assets/sounds/zapsplat_bell_service_desk_press_x2_18038.mp3')).play()
            }
          }
          commit('setMessages',res.messages);
        }
      }
    }
  },
  getUnseenMessagesTwo ({ dispatch, rootGetters, getters }) {
    let unread = getters['getUnread']
    let user = rootGetters['user/getUser']
    dispatch('emit', {
      end: "messages/setUnseenMessagesTwo",
      call: "a_getMessages",
      parameters:{
        unread: unread.length,
        user_id: user.id,//todo ovde treba po firmi ili poslovnici, da user koji je za samo jednu poslovnicu vidi samo svoje usere, a onaj koji je glavni za celu firmu vidi sve usere
      },
      response:"response"
    }, { root: true })
  },
  setUnseenMessagesTwo ({dispatch, commit}, data) {
    let res =  JSON.parse(data.response)
    if(typeof res.status == 'undefined'){
      if(typeof res.logout != 'undefined' && res.logout==1){
        window.onbeforeunload = function () {
        }
        dispatch('user/logout', null, { root: true })
      } else if(parseInt(res.messages.filter(m => m.status === 1).length) > parseInt((typeof data.parameters.unread != 'undefined')?data.parameters.unread:0)){
        var storedPermission = localStorage.getItem('notificationPermission');
        console.log('storedPermission',typeof storedPermission);
        if(storedPermission === null){
          dispatch('notification_modal', { modal_open: true, title: 'Nove poruke', text: 'Imate nove nepročitane poruke.' }, { root: true })
        }else if(storedPermission === 'granted'){
          showNotification('Imate nove nepročitane poruke.')
        }else if(storedPermission === 'denied') {
          commit('message_modal', { title: 'Nove poruke', text: 'Imate nove nepročitane poruke.' }, { root: true })
          new Audio(require('@/assets/sounds/zapsplat_bell_service_desk_press_x2_18038.mp3')).play()
        }
      }
      commit('setMessages',res.messages);
    }
  },
  get_message_by_id ({ dispatch, rootGetters }, id) {
    let user = rootGetters['user/getUser']
    dispatch('emit', {
      end: "messages/setMessage",
      call: "getMessageById",
      parameters:{
        user_id:user.id,
        message_id: id,//ovde treba po firmi ili poslovnici, da user koji je za samo jednu poslovnicu vidi samo svoje usere, a onaj koji je glavni za celu firmu vidi sve usere
      },
      response:"response"
    }, { root: true })
  },
  setMessage ({commit}, data) {
      commit('setMessage', JSON.parse(data.response))
  },
  getAllMessageTypes ({ dispatch }) {
    dispatch('emit', {
      end: "messages/setMessageTypes",
      call: "getMessageTypes",
      parameters:{
      },
      response:"[]"
    }, { root: true })
  },
  setMessageTypes ({commit}, data) {
      commit('setMessageTypes', JSON.parse(data.response))
  },
  changeMessageStatus ({ dispatch, rootGetters }, data) {
    let user = rootGetters['user/getUser']
    dispatch('emit', {
      end: "messages/setMessageStatus",
      call: "changeMessageStatus",
      parameters:{
        user_id: user.id,
        message_id: data.message_id,
        status: data.status
      },
      response:"response" //{message_id:1, status:0}
    }, { root: true })
  },
  setMessageStatus({commit}, data){
    let res = JSON.parse(data.response)
    if (res.status === 200) {
      commit('setMessageStatus', data.parameters)
    }
  },
  changeReplyStatus ({ dispatch, rootGetters }, data) {
    let user = rootGetters['user/getUser']
    dispatch('emit', {
      end: "messages/setReplyStatus",
      call: "changeReplyStatus",
      parameters:{
        user_id: user.id,
        reply_id: data.reply_id,
        message_id: data.message_id,
        status: data.status
      },
      response:"response" //{message_id:1, status:0}
    }, { root: true })
  },
  setReplyStatus({commit}, data){
    let res = JSON.parse(data.response)
    if (res.status === 200) {
      commit('setReplyStatus', data.parameters)
    }
  }
}

// mutations
const mutations = {
  setHash (state, data) {
    state.hash = data
  },
  setMessages (state, data) {
    state.messages = data
  },
  setMessage (state, data) {
    state.messages = [...state.messages.filter(m => m.id !== parseInt(data.id)), data]
  },
  setMessageTypes (state, data) {
    state.message_types = data
  },
  sortMessages(state){
    let messages = state.messages
    let sorted = messages.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    state.sorted_messages = sorted
  },
  setMessageStatus(state, data){
    console.log(state.messages)
    console.log(data)
    let message = state.messages.find(m => m.id === data.message_id)
    message.status = data.status
    state.messages = [...state.messages.filter(m => m.id !== parseInt(data.message_id)), message]
  },
  setReplyStatus(state, data){
    console.log(data)
    let message = state.messages.find(m => m.id === parseInt(data.message_id))
    let reply = message.replies.find(r => r.id === parseInt(data.reply_id))
    reply.status = data.status
    message.replies = [...message.replies.filter(r => r.id !== parseInt(data.reply_id)), reply]
    state.messages = [...state.messages.filter(m => m.id !== parseInt(data.message_id)), message]
  },
  setNewMessageReply(state, data){
    let message = state.messages.find(m => m.id === data.message_id)
    if(typeof message.replies === 'undefined'){
      message.replies = []
    }
    message.replies.push(data)
    state.messages = [...state.messages.filter(m => m.id !== parseInt(data.message_id)), message]
  },
  setNewMessage(state, data){
    state.messages.push(data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
