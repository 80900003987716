import router from "@/router"
// initial state
const state = {
    user: {
        id: 0,
        logged: 0,
        token: '',
        paymentMethods: [],
        bonbon:0,
        credentials: {
            narucivanje: 0,
            reklamacije: 0,
            useri: 0,
            kosarice: 0,
        }
    },
    default: {
        id: 0,
        logged: 0,
        token: '',
        paymentMethods: [],
        bonbon:0,
        credentials: {
            narucivanje: 0,
            reklamacije: 0,
            useri: 0,
            kosarice: 0,
        }
    },
    loginError: '',
    userFavs: []
}

// getters
const getters = {
    loggedIn: (state) => {
        return state.user.logged
    },
    getLoginError: (state) => {
        return state.loginError
    },
    getUser: (state) => {
        return state.user
    },
    userFavs: (state)=>{
        return state.userFavs
    },
    userBonbon: (state)=>{
        return state.user.bonbon
    },
    userId: (state) => {
        return state.user.id
    },
    userEmail: (state) => {
        return state.user.email
    },
    getSettings: (state) => {
        return state.user.settings
    },
    getCredentials: (state) => {
        return state.user.credentials
    },
    getPaymentMethods: (state) => {
        return state.user.paymentMethods
    },
    getUsername: (state) => {
        return state.user.username
    },
    getNotificationSettings: (state) => {
        return state.notification_settings
    },
    menuComponent: (state) => {
        return state.currentComponent
    }
}

// actions
const actions = {
    login_form({ dispatch }, form) {
        dispatch('emit', {
            end: "user/Login",
            call: "getLogin",
            parameters: form.data,
            response: "response"
        }, { root: true })
    },
    Login({ dispatch, commit }, data) {
        dispatch('forms/changeSubmitStatus', null, { root: true })
        let d = JSON.parse(data.response)
        if (d.status != 200) {
            commit('setLoginError', d)
            dispatch('disconnect')
        } else {
            commit('setUser', d.user)
            commit('forms/setSubmitStatus', null, { root: true })
            dispatch('cart/clearCart', [], { root: true })
            let route = (router.history.current.query.redirect&&router.history.current.query.redirect!='/login') ? router.history.current.query.redirect : '/'
            setTimeout(() => {
                router.push({ path: route })
                dispatch('disconnect')
            }, 2000)
        }
    },
    logout({ commit, dispatch }) {
        commit('unsetUser')
        dispatch('cart/clearCart', [], { root: true })
        router.push({ path: '/login' })
        dispatch('disconnect')
    },
    disconnect(){
        let vm = this._vm
        setTimeout((vm) => {
            vm.$disconnect()
        }, 500, vm)
    },
    getFavs ({ dispatch, rootGetters }) {
        let user = rootGetters['user/getUser']
        dispatch('emit', {
            end: "user/setFavs",
            call: "getFavs",
            parameters:{
                user_id: user.id
            },
            response:"response"
        }, { root: true })
    },
    setFavs ({commit}, data) {
        commit('setFavs', JSON.parse(data.response))
    },
    change_choosen_list({dispatch, rootGetters}, data){
        let user = rootGetters['user/getUser']
        dispatch('emit', {
            end: "user/getFavs",
            call: "change_choosen_list",
            parameters:{
                ...data, user_id: user.id
            },
            response:"response"
        }, { root: true })
    },
    getPM ({ dispatch, rootGetters }) {
        let user = rootGetters['user/getUser']
        dispatch('emit', {
            end: "user/setPM",
            call: "get_user_payment",
            parameters:{
                user_id: user.id
            },
            response:"response"
        }, { root: true })
    },
    setPM ({commit}, data) {
        commit('setPM', JSON.parse(data.response))
    },
    change_settings({ commit, dispatch, state }, payload) {
        commit('newSettings', { 'id': payload.id, 'name': payload.name })
        if (state.user.id !== 0) {
            let params = []
            dispatch('emit', { // todo nije u fajlu za sad nam ne treba
                end: "user/setSettings",
                call: "newSettings",
                parameters: params,
                response: payload.evento,
                status: 1
            }, { root: true })
        }
    },
    setSettings({ commit }, data) {
        let i = JSON.parse(data.response)
        i.forEach(function(a) {
            if (a.id != 0) {
                commit('newSettings', { 'name': a.name, 'id': a.id })
            }
        })
    },
    change_notification_settings({ commit, dispatch }, payload) {
        const old = state.user.notification_settings[payload.name]['value']
        commit('NotificationSettings', { 'name': payload.name, 'value': 1 })
        dispatch('emit', { //todo nije u fajlu ako nam zatreba
            end: "user/setNotificationSettings",
            call: "newNotificationSettings",
            parameters: {
                name: payload.name,
                value: !old
            },
            response: "response",
            status: 1
        }, { root: true })
    },
    setNotificationSettings({ commit }, data) {
        let i = JSON.parse(data.response)
        commit('NotificationSettings', { 'name': i.name, 'value': i.value })
    }
}

// mutations
const mutations = {
    setUser(state, user) {
        let u = {}
        u.id = user.id
        u.name = user.name
        u.user_type = user.user_type
        u.surname = user.surname
        u.phone = user.phone
        u.email = user.email
        u.token = user.token
        u.bonbon = user.bonbon
        u.logged = 1
        u.username = user.username
        u.settings = user.settings
        u.paymentMethods = user.paymentMethods
        u.credentials = user.credentials
        state.user = u
    },
    unsetUser(state) {
        state.user = state.default
    },
    setFavs(state, payload){
        state.userFavs = payload
    },
    setPM(state, payload){
        let pm = (payload.status==200)?payload.paymentMethods:[]
        state.user.paymentMethods = pm
    },
    setUserData(state, payload) {
        let user = state.user
        user[payload.data_name] = payload.data_value
        state.user = user
    },
    setUserAddress(state, payload) {
        let user = state.user
        let address = {...user.address }
        address.address = payload.address
        address.city = payload.city
        address.postal = payload.postal
        address.phone = payload.phone
        user.address = address
    },
    newSettings(state, payload) {
        state.user.settings[payload.name] = payload.id
    },
    NotificationSettings(state, payload) {
        state.user.notification_settings[payload.name] = payload.value
    },
    setMenuComponent(state, payload) {
        state.currentComponent = payload.component
    },
    setLoginError(state, payload) {
        if (payload.status === 401) {
            state.loginError = 'Pogrešni pristupni podaci, pokušajte ponovo'
        } else if (payload.status === 403) {
            state.loginError = 'provjerite podatke unešene u Vašu formu'
        }
    },
    clearLoginError(state) {
        state.loginError = ''
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}