import router from "@/router"
import EventBus from '@/event-bus'
// initial state
const state = {
    submitStatus: null,
    submitError: null,
    modal: { dialog: false },
    MessageReplyModal: false
}

// getters
const getters = {
    submitStatus: (state) => {
        return state.submitStatus
    },
    submitError: (state) => {
        return state.submitError
    },
    MessageReplyModal: (state) => {
        return state.MessageReplyModal
    },
    modal: (state) => {
        return state.modal
    }
}

// actions
const actions = {
    changeSubmitStatus({ commit }, status) {
        commit('setSubmitStatus', status)
    },
    changeSubmitError({ commit }, error) {
        commit('setSubmitError', error)
    },
    openMessageReplyModal({ commit }) {
        commit('openMessageReplyModal')
    },
    closeMessageReplyModal({ commit }) {
        commit('closeMessageReplyModal')
    },
    submitForm({ dispatch }, payload) {
        dispatch('emit', {
            end: 'forms/submitFormAction',
            call: payload.form,
            parameters: payload.data,
            response: "response"
        }, { root: true })
    },
    submitFormAction({ commit, dispatch }, data) {
        let res = JSON.parse(data.response)
        if (res.status == 200) {
            commit('setSubmitError', null)
            commit('setSubmitStatus', 'OK')
        }
        setTimeout(() => {
            //document.getElementById(data.call).reset()
            dispatch(data.call + 'Form', data)
        }, 200)
    },
    validators({ commit }, data) {
        console.log(commit)
        let res = JSON.parse(data.response)
        if (data.status == 200) {
            EventBus.$emit(res.event, (res.response == 1) ? true : false)
        } else {
            EventBus.$emit(res.event, false)
        }
    },
    modal({ commit }, data) {
        let res = JSON.parse(data.response)
        commit('setModal', { res: res, modal: data.call }) // todo zameniti sa rezultatom querya
    },
    personalInfoForm({ commit }, data) {
        let res = JSON.parse(data.response)
        commit('user/updateUserData', res.data, { root: true })
    },
    changeEmailForm({ commit }, data) {
        let res = JSON.parse(data.response)
        if (res.status == 200) {
            document.getElementById(data.call).reset()
        } else if (res.status == 402) {
            commit('setSubmitStatus', null)
            commit('setSubmitError', 'Pogrešna lozinka')
        } else if (res.status == 401) {
            commit('setSubmitStatus', null)
            commit('setSubmitError', 'Nešto nije u redu, pokušajte ponovo')
        }
    },
    changePasswordForm({ commit }, data) {
        let res = JSON.parse(data.response)
        if (res.status == 200) {
            document.getElementById(data.call).reset()
        } else if (res.status == 402) {
            commit('setSubmitStatus', null)
            commit('setSubmitError', 'Pogrešna stara lozinka')
        } else if (res.status == 401) {
            commit('setSubmitStatus', null)
            commit('setSubmitError', 'Nešto nije u redu, pokušajte ponovo')
        }
    },
    forgotPassForm({ commit }, data) {
        let res = JSON.parse(data.response)
        if (res.status == 200) {
            document.getElementById(data.call).reset()
            setTimeout(() => {
                //document.getElementById(data.call).reset()
                commit('setSubmitStatus', null)
            }, 10000)
        } else if (res.status == 400) {
            commit('setSubmitStatus', null)
            commit('setSubmitError', 'Ne postoji korisnik sa tom Email adresom, molimo koristite Email adresu povezanu sa Vašim računom')
        }
    },
    contactFormForm({ commit }, data) {
        let res = JSON.parse(data.response)
        document.getElementById(res.form).reset()
        setTimeout(() => {
            //document.getElementById(data.call).reset()
            commit('setSubmitStatus', null)
        }, 10000)
    },
    changePersonalInfoForm({ commit }, data) {
        let res = JSON.parse(data.response)
        if (res.status === 200) {
            commit('user/setUserAddress', data.parameters, { root: true })
        }
    },
    send_messageForm({ commit }, data) {
        let res = JSON.parse(data.response)
        if (res.status === 200) {
            document.getElementById(data.call).reset()
            commit('messages/setNewMessage', res, { root: true })
            setTimeout(() => {
                router.push({ path: '/komunikacija' })
            },2000)
        }
    },
    messageReplyModalForm({ commit, dispatch }, data) {
        let res = JSON.parse(data.response)
        if (res.status != 401) {
            document.getElementById(data.call).reset()
            commit('setSubmitStatus', null)
            dispatch('closeMessageReplyModal')
            commit('messages/setNewMessageReply', res, { root: true })
        }
    }
}

// mutations
const mutations = {
    setSubmitStatus(state, status) {
        state.submitStatus = status
    },
    setSubmitError(state, error) {
        state.submitError = error
    },
    openMessageReplyModal(state) {
        state.MessageReplyModal = true
    },
    closeMessageReplyModal(state) {
        state.MessageReplyModal = false
    },
    setModal(state, data) {
        let title, text
        let dialog = false
        switch (data.modal) {
            case 'gen_pass':
                dialog = true
                if (data.res.status == 200) {
                    title = 'Nova Lozinka je kreirana'
                    text = 'Vaša nova lozinka je poslana na Vašu Email adresu'
                } else if (data.res.status == 71) {
                    title = 'Zahtjev je istekao!'
                    text = 'Pokušajte napraviti još jedan, ako probelm ostane obratite se našoj službi za korisnike'
                } else if (data.res.status == 70) {
                    title = 'Neispravan zahtjev za oporavak lozinke'
                    text = 'Kontaktirajte našu službu za korisnike'
                }
                break;
            case 'ver_change':
                dialog = true
                if (data.res.status == 200) {
                    title = 'Vaše izmjene su potvrđene'
                    text = 'Izmjene će biti vidljive pri slijedećoj prijavi'
                } else if (data.res.status == 71) {
                    title = 'Zahtjev je istekao!'
                    text = 'Pokušajte napraviti još jedan, ako probelm ostane obratite se našoj službi za korisnike'
                } else if (data.res.status == 70) {
                    title = 'Neispravan zahtjev za izmjenu!'
                    text = 'Kontaktirajte našu službu za korisnike'
                }
                break;
            default:
                break
        }
        state.modal = { dialog: dialog, text: text, title: title }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}