import axios from 'axios'
import { mapGetters } from 'vuex'
export const versionReloadMixin = {
  data() {
    return {
      newVer: '',
      a:0
    }
  },
  computed: {
    ...mapGetters({
        ver: 'getVer',
    }),
  },
  methods: {
    initVersionCheck(frequency = 1000 * 60 * 5) {
      if(this.ver==='1.0.0'){
        this.checkVersion();
      }
      if(this.a){
        clearInterval(this.a)
      }else{
        let t = this
        this.a = setInterval(
          (t) => {
            t.checkVersion()
            if(!t.$store.getters['user/loggedIn']){
              clearInterval(t.a)
            }else{
              t.checkVersion()
            }
          },
          frequency,
          t,
          this.a
        )
      }
    },
    async checkVersion() {
      try {
        const fileResponse = await axios.create({
          headers: {
            'Content-type': 'application/json'
          }
        }).get('/version.json?t=' + new Date().getTime())

        this.newVer = fileResponse.data.ver
        console.log(this.newVer)
        console.log(this.ver)
        if(this.hasVerChanged(this.ver, this.newVer)){
          this.$store.commit('newVer_modal', { root: true })
        }
      } catch (error) {
        this.loading = false
        if (!error.response) {
          this.errorStatus = 'Error: Network Error'
        } else {
          this.errorStatus = error.response.data.message
        }
      }
    },
    hasVerChanged(currentVer, newVer) {
      if(currentVer==='1.0.0'){
        this.$store.dispatch('setNewVer', this.newVer, { root: true })
        return false
      }
      return currentVer !== newVer
    },
    reloadApp() {
      this.$store.dispatch('setNewVer', this.newVer, { root: true })
      this.$store.commit('newVer_modal_close', { root: true })
      setTimeout(()=>{ window.location.reload() }, 500)
    }
  }
}
