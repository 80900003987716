// initial state
import router from "@/router"
import EventBus from '@/event-bus'
// shape: [{ id, quantity }]
const state = {
    items: [],
    checkoutStatus: null,
    savedCartItems: [],
    order: {},
    items_pangleraj:[],
    items_pakeraj:[],
    order_napomena: '',
    savedCarts: [],
    ord_date: null,
    product_notes:[],
    order_notes:[],
    time: '',
    v_subjects:[],
    search:'',
    cart_id: 0,
    days: '',
    cart_name: '',
    cart_poslovnica: 0,
    cart_napomena: ''
}

// getters
const getters = {
        getSavedCarts: (state) => {
            return state.savedCarts
        },
        getTime: (state) => {
            return state.time
        },
        getCartId: (state) => {
            return state.cart_id
        },
        getCartName: (state) => {
            return state.cart_name
        },
        getCartNapomena: (state) => {
            return state.cart_napomena
        },
        getCartPoslovnica: (state) => {
            return state.cart_poslovnica
        },
        getCartDays: (state) => {
            return (state.days!=null)?state.days.split(','):[]
        },
        getSearch: (state) => {
            return state.search
        },
        get_v_subjects: (state) => {
            return state.v_subjects
        },
        cartProducts: (state, getters, rootState, rootGetters) => {
            let items =  state.items.map(({ id, quantity, napomena }) => {
                let gets = rootGetters['products/cartProducts']
                console.log(gets)
                const product = gets.find(product => product.id === id)
                return {
                    ...product,
                    quantity,
                    napomena: (typeof napomena === 'undefined') ? '' : napomena
                }
            })
            console.log(items)
            return items
        },
        cartPanglerajProducts: (state, getters) => {
            return (getters.cartProducts!==null)?getters.cartProducts.filter((i)=>i.composition.includes(5)):[]
        },
        cartPakerajProducts: (state, getters) => {
            return (getters.cartProducts!==null)?getters.cartProducts.filter((i)=>!i.composition.includes(5)):[]
        },
        cartProductsOrdered(state, getters){
            let sorted =  (getters.cartProducts!==null)?getters.cartProducts.sort((a, b) => (a.order < b.order) ? -1 : 1):[]
            console.log(sorted)
            return sorted
        },
        cartPakerajProductsOrdered(state, getters){
            let sorted =  (getters.cartPakerajProducts!==null)?getters.cartPakerajProducts.sort((a, b) => (a.order < b.order) ? -1 : 1):[]
            console.log("pankl", sorted)
            return sorted
        },
        cartPanglerajProductsOrdered(state, getters){
            let sorted =  (getters.cartPanglerajProducts!==null)?getters.cartPanglerajProducts.sort((a, b) => (a.order < b.order) ? -1 : 1):[]
            console.log("pangl",sorted)
            return sorted
        },
        getCartItems: (state) => {
            return state.items
        },
        getOrderNotes: (state) => {
            return state.order_notes
        },
        getProductNotes: (state) => {
            return state.product_notes
        },
        cartTotalPrice: (state, getters, /*rootState*/ ) => {
            let total = getters.cartProducts.reduce((total, product) => {
                    return total + product.parent_price * product.quantity
                }, 0)
                //total += rootState.products.shipment
            return total
        },
        getOrder: (state) => {
            return state.order;
        },
        getOrderNapomena: (state) => {
          return state.order_napomena
        },
        get_ord_date: (state) => {
            return state.ord_date
        }
    }
    // actions
const actions = {
    get_product_notes({dispatch, rootGetters}, payload){
        let user = rootGetters['user/getUser']
        console.log(payload)
        dispatch('emit', {
            end: "cart/setProductNotes",
            call: "getProductNotes",
            parameters:{
                user_id: user.id,//saljem ti id usera
                cIdent: payload.id//saljem ti cIdent producta
            },
            response: "response"
        }, { root: true })
    },
    setProductNotes({ commit }, data) {
        commit('setProductNotes', JSON.parse(data.response))
    },
    get_order_notes({dispatch, rootGetters}){
        let user = rootGetters['user/getUser']
        dispatch('emit', {
            end: "cart/setOrderNotes",
            call: "getOrderNotes",
            parameters:{
                user_id: user.id,//saljem ti id usera
            },
            response: "response"
        }, { root: true })
    },
    setOrderNotes({ commit }, data) {
        commit('setOrderNotes', JSON.parse(data.response))
    },
    getSavedCarts({ dispatch, rootGetters }) {
        let user = rootGetters['user/getUser']
        dispatch('emit', {
            end: "cart/setSavedCarts",
            call: "getSavedCarts",
            parameters: {
                user_id: user.id,
            },
            response: "response"
        }, { root: true })
    },
    setSavedCarts({ commit }, data) {
        commit('setSavedCarts', JSON.parse(data.response))
    },
    searchSubjects({ dispatch, commit }, payload) {
        commit('set_search', payload.search);
        if(payload.search.length>=3){
            dispatch('emit', {
                end: "cart/setSearchedSubjects",
                call: "search_v_subject",
                parameters: payload,
                response: "response"
            }, { root: true })
        }else{
            commit('set_v_subjects', [])
        }
    },
    setSearchedSubjects({ commit }, data) {
        commit('set_v_subjects', JSON.parse(data.response))
    },
    setSubject({ state }, data){
        console.log(state.order)
        EventBus.$emit('set_poslovnica', JSON.parse(data.response))
    },
    checkout() {
        router.push({ path: '/checkout' })
    },
    send_checkout({ commit }, payload) {
        commit('setOrderNapomena', {napomena:payload, no: 3})
        router.push({ path: '/finish_order' })
    },
    send_order({ commit, dispatch, getters, rootGetters, state }, payload) {
        let user = rootGetters['user/getUser']
        commit('setOrderPM', payload.pm)
        commit('setOrderDate', payload.date)
        commit('setOrderPoslovnicaId', payload.poslovnica_id)
        commit('setCheckoutStatus', null)
        let pakeraj = getters.cartPakerajProductsOrdered;
        let pangleraj = getters.cartPanglerajProductsOrdered;
        let hash_bound = null;
        let user_type = rootGetters['user/user_type'];
        let user_type_int = (typeof user_type !== 'undefined')?user_type:3;
        if(pakeraj.length>0 && pangleraj.length>0 && !(rootGetters['users/getPoslovnice'].length === 1 && rootGetters['users/getPoslovnice'][0]['document']!==null&&user_type_int!=5)){
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            var length = 6
            var charactersLength = characters.length
            var result_c           = ''
            for ( var i = 0; i < length; i++ ) {
                result_c += characters.charAt(Math.floor(Math.random() * charactersLength))
            }
            hash_bound = result_c;
        }
        if(pakeraj.length>0){
            var param = {
                type: 1,
                user_id: user.id,
                products: pakeraj.map(({ id, quantity, napomena, parent_price}) => {
                    return {
                        id,
                        quantity,
                        price: parent_price,
                        napomena: (typeof napomena === 'undefined')?'':napomena,
                    }
                }),
                ...state.order,
                bound: hash_bound,
                napomena:  state.order_napomena
            }
            dispatch('emit', {
                end: "cart/setCartItems",
                call: "createOrder",
                parameters: param,
                response: "response"
            }, { root: true })
        }
        
        if(pangleraj.length>0){
            var param2 = {
                type: 2,
                user_id: user.id,
                products: pangleraj.map(({ id, quantity, napomena, parent_price}) => {
                    return {
                        id,
                        quantity,
                        price: parent_price,
                        napomena: (typeof napomena === 'undefined')?'':napomena,
                    }
                }),
                ...state.order,
                bound: hash_bound,
                napomena:  state.order_napomena
            }
            dispatch('emit', {
                end: "cart/setCartItems",
                call: "createOrder",
                parameters: param2,
                response: "response"
            }, { root: true })
        }
        commit('setOrderNapomena', {napomena:'', no: 100})
    },
    setCartItems({ /*state,*/ commit }, data) {
      let res = JSON.parse(data.response)
      if (res.status == 200) {
        commit('setCartItems', [])
        commit('setOrderNapomena', {napomena:'', no: 7})
        commit('newOrder', {})
        router.push({ path: '/successful_order' })
      }else if (res.status == 201){
        commit('message_modal', { title: 'Greška', text: 'Narudžba je već kreirana, proverite arhivu i da li vam je prazna košarica' }, { root: true })
      }else{
        commit('message_modal', { title: 'Uspjeh', text: 'Narudža nije snimljena. Pokušajte ponovo.' }, { root: true })// tr
      }
    },
    save_cart({ dispatch, rootGetters, state }, payload) {
        let user = rootGetters['user/getUser']
        let parameters = {
            user_id: user.id,
            products: state.items,
            name: payload.name,
            company: payload.company,
            days: payload.days,
            napomena: state.order_napomena,
            id: 0
        }
        if(typeof payload.id != 'undefined'){
            parameters.id = payload.id
        }
        dispatch('emit', {
            end: "cart/saveNewCart",
            call: "createSavedCart",
            parameters: parameters,
            response: "response" // sta saljem plus id koji mu je dodeljen, datum kad je napravljen i ime usera koji je dodao
        }, { root: true })
    },
    delete_saved_cart({ dispatch, rootGetters }, payload) {
        let user = rootGetters['user/getUser']
        dispatch('emit', {
            end: "cart/getSavedCarts",
            call: "deleteSavedCart",
            parameters: {
                user_id: user.id,
                id: payload
            },
            response: "response" // sta saljem plus id koji mu je dodeljen, datum kad je napravljen i ime usera koji je dodao
        }, { root: true })
    },
    saveNewCart({ commit }, payload) {
        let res = JSON.parse(payload.response)
        if (res.status == 200) {
            commit('message_modal', { title: 'Uspjeh', text: 'Košarica je uspješno spremljena. Možete nastaviti kupnju.' }, { root: true })
        } else {
            commit('message_modal', { title: 'Greška', text: 'Košarica nije spremljena, pokušajte ponovo' }, { root: true })
        }
    },
    setOrderNapomena({ commit }, payload) {
        commit('setOrderNapomena', {napomena:payload, no: 1})
    },
    clearCart({ /*state,*/ commit }, data) {
        commit('setCartItems', data)
        commit('newOrder', {})
        commit('setOrderNapomena', {napomena:'', no: 2})
    },
    addProductToCart({ state, commit }, payload) {
        commit('setCheckoutStatus', null)
        const cartItem = state.items.find(item => item.id === payload.id)
        if (!cartItem) {
            commit('pushProductToCart', { id: payload.id, step: payload.step })
        } else {
            commit('incrementItemQuantity', { cart_item: cartItem, step: payload.step })
        }
    },
    removeProductFromCart({ state, commit }, payload) {
        commit('setCheckoutStatus', null)
        const cartItem = state.items.find(item => item.id === payload.id)
        if (cartItem.quantity <= 0 || cartItem.quantity - payload.step <= 0) {
            commit('removeProduct', { id: payload.id })
        } else {
            commit('decrementItemQuantity', { cart_item: cartItem, step: payload.step })
        }
    },
    changeProductToCart({ state, commit }, payload) {
        commit('setCheckoutStatus', null)
        let qty = parseFloat(payload.qty)
        const cartItem = state.items.find(item => item.id === payload.id)
        if (qty == 0) {
            commit('removeProduct', { id: payload.id })
        } else {
            commit('setItemQuantity', { cart_item: cartItem, qty: qty })
        }
    },
    finishOrder({ dispatch, rootGetters, state }) { //todo nisam dodao u api fajl
        let user = rootGetters['user/getUser']
        dispatch('emit', {
            end: "cart/finishBack",
            call: "createOrder",
            parameters: {
                user_id: user.id,
                products: state.items
            },
            response: "response"
        }, { root: true })
    },
    get_time({ dispatch }, payload) { //todo nisam dodao u api fajl
        dispatch('emit', {
            end: "cart/setTime",
            call: "getTime",
            parameters: payload,
            response: "response"
        }, { root: true })
    },
    setTime({ commit }, data) {
        let res = data.response
        commit('setTime', res)
        EventBus.$emit(data.parameters.event)
    },
    activateSavedCart({ commit, rootGetters, state }, id) {
        let saved = state.savedCarts.find(item => item.id === id)
        let products = rootGetters['products/cartProducts']
        let saved_items = saved.products.filter(item => products.find(i => i.id === item.id))
        commit('activateSavedCart', { saved, saved_items })
        router.push({ path: '/cart' })
    },
    finishBack({ commit }, payload) {
        let res = JSON.parse(payload.response)
        if (res.status == 201) {
            commit('message_modal', { title: 'Greška', text: 'Narudžba je već kreirana, proverite arhivu i da li vam je prazna košarica' }, { root: true })
        }else if (res.status == 200) {
            commit('setCartItems', [])
            commit('setOrderNapomena', {napomena:'', no:4})
        }else{
            Error('Something went wrong')
        }
    },
    set_ord_date({ commit }, date){
        commit('set_ord_date', date)
    }
}

// mutations
const mutations = {
    set_ord_date(state, date){
        state.ord_date = date
    },
    setTime(state, time){
        state.time = time
    },
    set_search(state, search){
        state.search = search
    },
    setOrderNotes(state, data){
        state.order_notes = data
    },
    setProductNotes(state, data){
        state.product_notes = data
    },
    pushProductToCart(state, payload) {
        state.items.push({
            id: payload.id,
            quantity: payload.step
        })
    },
    set_v_subjects(state, payload){
        state.v_subjects = payload
    },
    setSavedCarts(state, savedCarts) {
        state.savedCarts = savedCarts
    },
    activateSavedCart(state, payload) {
        state.order_napomena = payload.saved.napomena
        state.items = payload.saved_items
        state.cart_id = payload.saved.id
        state.days = payload.saved.days
        state.cart_name = payload.saved.name
        state.cart_poslovnica = payload.saved.company
    },
    removeProduct(state, { id }) {
        let index = state.items.findIndex(item => item.id === id)
        state.items.splice(index, 1)
    },
    setOrderPM(state, pm) {
        state.order.pm = parseInt(pm)
    },
    setOrderDate(state, date) {
        state.order.date = date
    },
    setOrderPoslovnicaId(state, poslovnica) {
        state.order.poslovnica_id = poslovnica
    },
    setOrderNapomena(state, payload) {
        console.log(payload)
        console.log(payload.no)
        state.order_napomena = payload.napomena
    },
    setNapomena(state, payload) {
        const cart_item = state.items.find(item => item.id === payload.id)
        cart_item.napomena = payload.napomena
        const index = state.items.findIndex(item => item.id === payload.id)
        if (index !== -1) state.items.splice(index, 1, cart_item)
    },
    setSelectedDays(state, days) {
        state.days = days.join(',')
    },
    incrementItemQuantity(state, payload) {
        payload.cart_item.quantity = payload.cart_item.quantity + payload.step
    },
    decrementItemQuantity(state, payload) {
        payload.cart_item.quantity = payload.cart_item.quantity - payload.step
    },
    setItemQuantity(state, payload) {
        payload.cart_item.quantity = payload.qty
    },
    setCartItems(state, data) {
        state.items = data
    },
    savedCartItems(state, data) {
        state.savedCartItems = data
    },
    setCheckoutStatus(state, status) {
        state.checkoutStatus = status
    },
    addNewSavedCart(state, newCart) {
        state.savedCarts.push(newCart)
    },
    newOrder(state, order_data) {
        state.order = order_data
    },
    addOrderId(state, id) {
        state.order = {...state.order, id }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}