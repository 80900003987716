// initial state
const state = {
  all: [],
  izdvajamo: [],
  subject_price: [],
  categories: [],
  main_categories: []
}

// getters
const getters = {
  cartProducts: (state, getters, rootState) => {
    if(!state.all.length){
      return {}
    }
    return state.all.map((item) => {
      const cart_item = rootState.cart.items.find(cart_item => cart_item.id === item.id)
      return {
        ...item,
        parent_price: (typeof state.all.find(i => i.id === item.cClassif3) !== "undefined") ? state.all.find(i => i.id === item.cClassif3).price : item.price,
        quantity: (cart_item)?cart_item.quantity:0
      }
    })
  },
  getCategories: (state) => {
    return state.categories
  },
  getMainCategories: (state) => {
    return state.main_categories
  },
  getSubCategories: (state, getters) => (mcat_id) => {
    if (!getters.getCategories.length) return []
    let subs = getters.getCategories.filter(function(item) {
        return item.mcat_id == mcat_id
    })
    return subs
  },
  catProducts: (state, getters, rootState) => (cat_id) => {
    if(!getters.cartProducts.length) return []
    let prods = []
    if(cat_id>0){
      let category_id = parseInt(cat_id)
      prods = getters.cartProducts.filter(function(item) {
          return item.category == category_id
      })
    }else{
      let search = rootState.article_search.toLowerCase()
      prods = getters.cartProducts.filter(function(item) {
        return (item.title!=null&&item.title.toLowerCase().includes(search))||item.id.toLowerCase().startsWith(search)
      })
    }
    return prods
  },
  izdvajamoProducts: (state, getters, rootState) => {
    if(!state.izdvajamo.length){
      return {}
    }
    return state.izdvajamo.map((item) => {
      const product_item = state.all.find(p_item => p_item.id === item.id)
      const cart_item = rootState.cart.items.find(c_item => c_item.id === item.id)
      return {
        ...product_item,
        date: item.date,
        quantity: (cart_item)?cart_item.quantity:0
      }
    })
  },
  productDetails: (state, getters, rootState) => (id) => {
    if(!state.all.length){
      return {}
    }
    let prod = state.all.find((p) => p.id === parseInt(id)) || {}
    const cart_item = rootState.cart.items.find(cart_item => cart_item.id === parseInt(id))
    prod.quantity = (cart_item)?cart_item.quantity:0
    return prod
  }
}

// actions
const actions = {
  getAllProducts ({ dispatch }) {
    dispatch('emit', {
      end: "products/setPackages",
      call: "getPackages",
      parameters: {},
      response: "response",
      status: 1
    }, { root: true })
  },
  setPackages ({ dispatch, commit, rootState}, data) {
    commit('setProducts', JSON.parse(data.response))
    let poslovnice = rootState.users.poslovnice
    let poslovnica = rootState.users.poslovnica
    if(poslovnice.length==1){
        dispatch('setSubjectPrice', {'subject':poslovnice[0].title, s:true});
    }else if(poslovnica!=''){
        dispatch('setSubjectPrice', {'subject':poslovnica, s:true});
    }
  },
  setSubjectPrice ({ commit, dispatch, rootState }, data) {
    console.log(rootState.users.poslovnica+'!=='+data.subject)
    console.log(data)
    let poslovnice = rootState.users.poslovnice
    let item = poslovnice.find(i=>{
      console.log(i.title+'==='+data.subject)
      return i.title === data.subject
    })
    if(typeof item != 'undefined') dispatch('users/setGroup', item.grupa, { root: true })
    if(rootState.users.poslovnica!==data.subject||(rootState.user.user_id!=141&&!data.s)||(rootState.user.user_id==141&&data.s)){
      if(rootState.users.poslovnica!==data.subject){
        console.log(111, data.subject)
        dispatch('users/setPoslovnica', data.subject, { root: true })
      }else{
        console.log(222)
        dispatch('emit', {
          end: "products/resetPackages",
          call: "getPackagesBySubject",
          parameters: {subject: data.subject},
          response: "response",
          status: 1
        }, { root: true })
      }
    }else{
      console.log(333)
      rootState.products.subject_price.map(item =>{
        let all = rootState.products.all
        let changed = all.find(p=>p.id === item.cIdent)
        changed.price = item.price
        commit('setProducts', [...all.filter(p=>p.id !== item.cIdent), changed])
      })
    }
  },
  resetPackages ({ commit, dispatch, rootState }, data) {
    let d = JSON.parse(data.response);
    if(typeof d.status == 'undefined' && d.length>0){
      commit('setSubjectPrice', d)
      d.map(item =>{
        let all = rootState.products.all
        let changed = all.find(p=>p.id === item.cIdent)
        changed.price = item.price
        commit('setProducts', [...all.filter(p=>p.id !== item.cIdent), changed])
      })
    }else if(rootState.products.subject_price.length > 0){
      commit('setSubjectPrice', [])
      dispatch('getAllProducts')
    }
  },
  getIzdvajamo ({ dispatch }) {
    dispatch('emit', {
      end: "products/setIzdvajamo",
      call: "getIzdvajamo",
      parameters: {},
      response: "[]",//[{\"id\":1,\"date\":\"2020-08-14 17:28:49.000000\",\"product_id\":1},{\"id\":2,\"date\":\"2020-08-14 17:28:49.000000\",\"product_id\":3},{\"id\":3,\"date\":\"2020-08-14 17:28:49.000000\",\"product_id\":5}]
      status: 1
    }, { root: true })
  },
  setIzdvajamo ({ commit }, data) {
    commit('setIzdvajamo', JSON.parse(data.response))
  },
  getCategories ({ dispatch }) {
    dispatch('emit', {
      end: "products/setCategories",
      call: "a_getCategory",
      parameters: {},
      response: "response",
      status: 1
    }, { root: true })
  },
  setCategories ({ commit }, data) {
    commit('setCategories', JSON.parse(data.response))
  },
  getMainCategories ({ dispatch }) {
    dispatch('emit', {
      end: "products/setMainCategories",
      call: "a_getMainCategory",
      parameters: {},
      response: "response",
      status: 1
    }, { root: true })
  },
  setMainCategories ({ commit }, data) {
    commit('setMainCategories', JSON.parse(data.response))
  },
  getProductDetails ({ dispatch, rootState }, payload) {
    const user = rootState.user.user
    let parameters = {
      product_id: payload,
      user_id: user.id
    }
    dispatch('emit', {//todo ako nam zatreba nije u fajlu
      end: "products/setProductDetails",
      call: "getProductDetails",
      parameters: parameters,
      response: "response",
      status: 1
    }, { root: true })
  },
  setProductDetails ({ commit }, data) {
    commit('setProduct', JSON.parse(data.response))
  },
}

// mutations
const mutations = {
  setProducts (state, data) {
    state.all = data
  },
  setIzdvajamo(state, data){
    state.izdvajamo = data
  },
  setSubjectPrice(state, data){
    state.subject_price = data
  },
  setCategories (state, data) {
    state.categories = data
  },
  setMainCategories (state, data) {
    state.main_categories = data
  },
  setShipment (state, data) {
    state.shipment = data.shipment
  },
  setProduct (state, data) {
    const product = state.all.find(product => product.id === data.prod_id)
    console.log(data)
    product.desc = data.prod_desc
    product.products = data.products
    state.all = [
      ...state.all.filter(element => element.id !== data.prod_id),
      product
    ]
  },
  decrementProductInventory (state, { id }) {
    const product = state.all.find(product => product.id === id)
    product.inventory--
  },

  incrementProductInventory (state, { id }) {
    const product = state.all.find(product => product.id === id)
    product.inventory++
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
