const state = {
    menu:[]
}

// getters
const getters = {
    menu: (state, getters, rootState, rootGetters) => {
        let creds = rootGetters['user/getCredentials']
        let icons = []
        if (creds.narucivanje != 0) icons.push({ 'icon': 'add_to_cart', 'name': 'shop', 'route': '/shop', 'text': 'Shop' })
        if (creds.kosarice != 0) icons.push({ 'icon': 'cart', 'name': 'moje_kosarice', 'route': '/moje_kosarice', 'text': 'Moje košarice' })
        icons.push({ 'icon': 'arhiva', 'name': 'arhiva', 'route': '/orders', 'text': 'Arhiva' })
        //icons.push({'icon':'statistika','name':'statistika','route':'/statistika','text': 'Statistike'})
        //if(creds.useri!=0) icons.push({'icon':'users','name':'users','route':'/users','text': 'Korisnici'})
        if (creds.reklamacije != 0) icons.push({ 'icon': 'komunikacija', 'name': 'komunikacija', 'route': '/komunikacija', 'text': 'Komunikacija' })
        icons.push({'icon':'user','name':'user','route':'/profil','text': 'Profil'})
        return icons
    },
    menu_icons(state){
        return state.menu
    }
}

// actions
const actions = {
    getMenu({ dispatch, rootGetters }) {
        let menu = rootGetters['data/menu']
        dispatch('emit', {
            end: "data/setMenu",
            call: "getMenu",
            parameters: {},
            response: JSON.stringify(menu)
        }, { root: true })
    },
    setMenu({ commit }, data) {
        commit('setMenu', JSON.parse(data.response))
    },
}

// mutations
const mutations = {
    setMenu(state, m) {
        state.menu = m
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}