import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import vuetify from './plugins/vuetify'
import CustomFn from './plugins/custom_fn'
import 'vuetify/dist/vuetify.min.css'
import './assets/css/style.css'
import { currency } from './currency'
import Vuelidate from 'vuelidate'
import VueLocalStorage from 'vue-localstorage'
import { conf } from '@/config.js'
import VEager from '@kuroski/v-eager'//fix za input-model na nekim uredjajima
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse'
import VueNativeSock from 'vue-native-websocket'
import checkView from 'vue-check-view'
import VueOffline from 'vue-offline'
//import VueInstall from "vue-pwa-install"

//Vue.use(VueInstall)
Vue.use(VueOffline)
Vue.use(checkView)
Vue.use(Vuelidate)
Vue.use(VueFilterDateParse)
Vue.use(VueFilterDateFormat)
Vue.use(VEager)
Vue.filter('currency', currency)
Vue.use(CustomFn)
Vue.use(VueLocalStorage)
Vue.use(VueNativeSock, conf.WS, { connectManually: true, store: store, format: 'json', passToStoreHandler: function (eventName, event) {
  if (!eventName.startsWith('SOCKET_')) { return }
  let method = 'commit'
  let target = eventName.toUpperCase()
  let msg = event
  if (this.format === 'json' && event.data) {
    let all = event.data.split("}\n{")
    all.forEach((el, index) => {
      msg = JSON.parse((all.length>1)?((index % 2 == 0)?el+'}':'{'+el):el)
      if (msg.mutation) {
        target = [msg.namespace || '', msg.mutation].filter((e) => !!e).join('/')
      } else if (msg.action) {
        method = 'dispatch'
        target = [msg.namespace || '', msg.action].filter((e) => !!e).join('/')
      }
      this.store[method](target, msg)
    })
  }else{
    this.store[method](target, msg)
  }
}})

Vue.config.productionTip = conf.ENV

/*const VM = */new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount("#app")
//VM.$connect(conf.WS+'?ch='+conf.WS_CH+'&id=0')
