// initial state
const state = {
  poslovnice: [],
  poslovnica: '',
  group: 0,
  users: [],
}
import EventBus from '@/event-bus'
// getters
const getters = {
  getPoslovnice: (state) => {
    return state.poslovnice
  },
  getPoslovnica: (state) => {
    return state.poslovnica
  },
  getGroup: (state) => {
    return state.group
  },
  getUsers(state){
    return state.users.map((user) => {
      const poslovnica = state.poslovnice.find(p => p.id === user.poslovnica_id)
      return {
        ...user,
        poslovnica: poslovnica.title,
      }
    })
  },
  user_by_id: (state) => (id) => {
    id = parseInt(id)
    if(!state.users.length||id==0){
      return {}
    }
    const user = state.users.find(u => u.id === id)
    const poslovnica =  state.poslovnice.find(p => p.id === user.poslovnica_id)
    return {
        ...user,
        poslovnica: poslovnica.title
    }
  }
}

// actions
const actions = {
  getAllUsers ({ dispatch, rootGetters }) {
    let user = rootGetters['user/getUser']
    dispatch('emit', {
      end: "users/setUsers",
      call: "getUsers",
      parameters:{
        user_id: user.id,//ovde treba po firmi ili poslovnici, da user koji je za samo jednu poslovnicu vidi samo svoje usere, a onaj koji je glavni za celu firmu vidi sve usere
      },
      response:"response"
    }, { root: true })
  },
  setUsers ({commit}, data) {
    commit('setUsers', JSON.parse(data.response))
  },
  getPoslovnice ({ dispatch, rootGetters }) {
    let user = rootGetters['user/getUser']
    dispatch('emit', {
      end: "users/setPoslovnice",
      call: "getPoslovnice",
      parameters:{
        user_id: user.id//isto ovo odozgo iz usera
      },
      response:"response"
    }, { root: true })
  },
  setPoslovnice ({commit, dispatch}, data) {
    let d = JSON.parse(data.response)
    commit('setPoslovnice', d)
    if(d.length==1){
      dispatch('products/setSubjectPrice', {subject: d[0].title, s:false}, { root: true });
    }
    commit('setGroup', d[0].grupa)
  },
  changeUserStatus ({ dispatch }, user_id) {
    const user = state.users.find(item => item.id === user_id) 
    dispatch('emit', {
      end: "users/setUserStatus",
      call: "changeUserStatus",
      parameters:{//todo proveravati da li je user od usera, tj da li su u istoj firmi i da li ovaj koji salje req ima pravo da menja usere
        user_id: user.id,
        status: (user.status)?0:1
      },
      response:"response" //{user_id:1, status:0}
    }, { root: true })
  },
  setUserStatus({commit}, data){
    let res = JSON.parse(data.response)
    commit('setUserStatus', res)
  },
  setPoslovnica({commit}, data){
    console.log('poslovnica->', data)
    if (window.location.href.endsWith('finish_order')) {
      EventBus.$emit('set_poslovnica_2', data)
    }else{
      commit('setPoslovnica', data)
    }
  },
  setPoslovnica2({commit}, data){
      commit('setPoslovnica', data)
  },
  setGroup({commit}, data){
    console.log('group->', data)
    commit('setGroup', data)
  },
  changeUserCredentials ({ dispatch }, payload) {
    dispatch('emit', {
      end: "users/setUserCredentials",
      call: "changeUserCredentials",
      parameters:{//todo proveravati da li je user od usera, tj da li su u istoj firmi i da li ovaj koji salje req ima pravo da menja usere
        user_id: payload.user_id,
        credentials: payload.credentials
      },
      response:"response"//vraca {user_id:1, credentials:'ime_credentialsa', value:[1,0]}
    }, { root: true })
  },
  setUserCredentials({commit}, data){
    let res = JSON.parse(data.response)
    if(res.status!=200){
      Error('Something went wrong')// todo zameniti sa statusnim porukama
    }else{
      commit('setUserCredentials', res)
    }
  }
}

// mutations
const mutations = {
  setUsers (state, data) {
    state.users = data
  },
  setPoslovnice (state, data) {
    console.log('poslovnice', state.poslovnice)
    state.poslovnice = data
    console.log('poslovnice2', state.poslovnice)
  },
  setPoslovnica (state, data) {
    state.poslovnica = data
  },
  setGroup (state, data) {
    state.group = data
  },
  setUserCredentials (state, data) {
    let user = state.users.find(u => u.id === data.user_id)
    user.credentials[data.credentials] = data.value
    state.users = [...state.users.filter(u => u.id !== parseInt(data.user_id)), user]
  },
  setUserStatus(state, data){
    let user = state.users.find(u => u.id === data.user_id)
    user.status = data.status
    state.users = [...state.users.filter(u => u.id !== parseInt(data.user_id)), user]
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
