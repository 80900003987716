import Vue from 'vue'
import Vuex from 'vuex'
import cart from './modules/cart'
import products from './modules/products'
import user from './modules/user'
import users from './modules/users'
import orders from './modules/orders'
import messages from './modules/messages'
import data from './modules/data'
import forms from './modules/forms'
import VuexPersist from 'vuex-persist'
import { conf } from '@/config.js'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const vuexPersist = new VuexPersist({
    key: 'bodirkovicWebShop',
    storage: window.localStorage
})
const state = {
    article_search: '',
    search: 'close',
    product_view: false,
    message_modal: {
        modal_open: false
    },
    socket: {
        isConnected: false,
        message: '',
        reconnectError: false,
    },
    newVer_modal: {
        modal_open: false
    },
    ver: '1.0.0',
    canal_hash: '',
    notification:{
        modal_open: false,
        title:'',
        text:'',
    }
}
const onchangeTrigger = store => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
        console.log(mutation)
        console.log(state)
            // called after every mutation.
            // The mutation comes in the format of `{ type, payload }`.
    })
}
const vuexStorage = new VuexPersist({
    key: 'sourcelink',
    plugins: [onchangeTrigger, vuexPersist.plugin]
        // You can change this explicitly use 
        // either window.localStorage  or window.sessionStorage
        // However we are going to make use of localForage
})



const getters = {
    isAuthenticated: () => {
        return state.user.user.logged
    },
    getArticleSearch: (state) => {
        return state.article_search
    },
    getProductView: (state) => {
        return state.product_view
    },
    messageModal: (state) => {
        return state.message_modal
    },
    newVerModal: (state) => {
        return state.newVer_modal
    },
    getVer: (state) => {
        return state.ver
    },
    notification: (state) => {
        return state.notification
    }
}

const actions = {
        message_modal({ commit }, payload) {
            commit('message_modal', payload)
        },
        message_modal_close({ commit }) {
            commit('message_modal_close')
        },
        notification_modal({ commit }, payload) {
            commit('notification_modal', payload)
        },
        notification_modal_close({ commit }) {
            commit('notification_modal_close')
        },
        toggle_search({ commit }) {
            commit('search')
        },
        toggle_product_view({ commit }) {
            commit('product_view')
        },
        setNewVer({commit}, payload){
            commit('setVer', payload)
        },
        emit({ state, rootGetters, dispatch, commit }, payload) {
            let user_id = rootGetters['user/userId']
            console.log('to ' + payload.call)
            console.log('payload' + payload)
            console.log(state.socket)
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            var length = 10
            var charactersLength = characters.length
            if(state.canal_hash==''){
                var result_c           = ''
                for ( var i = 0; i < length; i++ ) {
                    result_c += characters.charAt(Math.floor(Math.random() * charactersLength))
                }
                commit('setCanalHash', result_c)
            }
            if (!state.socket.isConnected || typeof Vue.prototype.$socket === 'undefined') {
                if (typeof Vue.prototype.$socket === 'undefined' || Vue.prototype.$socket.readyState === 3) {
                  let p = user_id
                  if(user_id==0){
                    p = state.canal_hash
                  }
                  this._vm.$connect(conf.WS + '?ch=' + conf.WS_CH + '&id=' + p)
                }
                setTimeout(() => { dispatch('emit', payload) }, 1000)
            } else {
                payload.parameters.canal_hash = state.canal_hash;
                console.log('hash',payload.parameters.canal_hash);
                console.log(payload)
                Vue.prototype.$socket.sendObj(payload)
            }
        },
        fromSocket({ dispatch, state/*, commit*/ }, payload) {
            if(payload.cs != 1 || (typeof payload.parameters.canal_hash!= 'undefined' && payload.parameters.canal_hash != state.canal_hash)) {
                console.log(payload.cs)
                /*commit('message_modal', { title: 'Greška', text: 'Komunikacija sa bazom u prekidu, molimo pokušajte kasnije.' }, { root: true }) //tr
                setTimeout(()=>{ commit('message_modal_close', { root: true }) }, 3000);*/
            } else{
                console.log('from ' + payload.call)
                console.log(payload)
                payload.status = 200 // todo - remove this
                    //console.log(JSON.parse(payload.response))
                dispatch(payload.end, payload)
            }
        }
    }
    // mutations
const mutations = {
    setArticleSearch(state, payload) {
        state.article_search = payload
    },
    product_view(state) {
        state.product_view = !state.product_view
    },
    message_modal(state, payload) {
        state.message_modal = {...payload, modal_open: true }
    },
    message_modal_close(state) {
        state.message_modal = { modal_open: false }
    },
    notification_modal: (state, data) => {
        state.notification = data
    },
    notification_modal_close(state) {
        state.notification = { modal_open: false }
    },
    socket_isDisconnected() {
        this.commit("socketOpen", false)
    },
    setVer(state, data){
        state.ver = data
    },
    setCanalHash(state, data){
        state.canal_hash = data
    },
    newVer_modal(state) {
        state.newVer_modal = { modal_open: true }
    },
    newVer_modal_close(state) {
        state.newVer_modal = { modal_open: false }
    },
    SOCKET_ONOPEN(state, event) {
        Vue.prototype.$socket = event.currentTarget
        setTimeout(() => {
            this.commit("socketOpen", true)
        }, 50)
        setTimeout(() => {
            console.log('okidam')
            this.dispatch('data/getMenu', null, { root: true })
            this.dispatch('users/getPoslovnice', null, { root: true })
            this.dispatch('products/getAllProducts', null, { root: true })
            this.dispatch('products/getIzdvajamo', null, { root: true })
        }, 1000)
    },
    SOCKET_ONCLOSE(state, event) {
        this.commit("socketOpen", false)
        console.log(event)
    },
    SOCKET_ONERROR(state, event) {
        console.log(event)
        console.error(state, event)
        //this.commit('message_modal', { title: 'Greška', text: 'Komunikacija sa bazom u prekidu, molimo pokušajte kasnije.' }, { root: true })
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
        console.log('message')
        console.log(message)
        state.socket.message = message
        this.dispatch('fromSocket', message)
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
        console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
        state.socket.reconnectError = true
    },
    socketOpen: (state, payload) => {
        state.socket.isConnected = payload
    }
}
export default new Vuex.Store({
    plugins: [vuexStorage.plugin],
    actions,
    mutations,
    getters,
    state,
    modules: {
        user,
        users,
        data,
        cart,
        products,
        orders,
        forms,
        messages
    },
    strict: debug,
})