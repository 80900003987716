<template>
    <div>
        <v-container
            class="fill-height"
            fluid
        >
            <v-row
                align="center"
                justify="center"
            >
                <v-col cols="12" style="min-height:40px; margin:20px 0">
                    <router-link to="/" class='text-center'>
                        <v-img :src="require(`@/assets/img/logo.png`)" max-height="140" contain></v-img>
                    </router-link>
                </v-col>
                <v-col cols="12" class="fill-height">
                    <div class="ma" style="max-width:400px; margin:0 auto">
                        <router-view :key="$route.path" />
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div v-if="open" class="cookie_warning">
            <v-icon fab color="#FFF" @click="close()" class="close">fas fa-times</v-icon>
            <h5>Ova stranica koristi kolačiće</h5>
            <p>Kolačiće koristimo samo za identifikaciju korisnika i čuvanja njihovih podešavanja. Vi pristajete na korištenje kolačića samim tim što koristite ovu stranicu u svoje svrhe</p>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      open: true,
    }
  },
  methods: {
    close () {
        this.open = false
    },
  }
};
</script>
<style scoped>
.cookie_warning{
    padding:10px;
    position:fixed;
    bottom:0;
    height:auto;
    width:100%;
    left:0;
    right:0;
    background-color: rgba(0,0,0,.6);
    color:#FFF;
    z-index:10000;
}
@media screen and (max-width: 1263px) {
    .cookie_warning{
        position:relative;
        height:auto;
        width:auto;
    }
}
.cookie_warning .close{
    position:absolute;
    right:5px;
    top:5px;
}
.cookie_warning p{
    margin:10px 0 0 0;
    font-size:10px;
}
</style>