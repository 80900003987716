import store from '../store'
export default {
    install (Vue/*, options*/) {
        Vue.prototype.isNumber = (step, id, evt) => {
            evt = (evt) ? evt : window.event
            var charCode = (evt.which) ? evt.which : evt.keyCode
            console.log(charCode)
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 92) {
                console.log(charCode)
                evt.preventDefault()
            }
            clearTimeout(this.timer)
            this.timer = setTimeout((step, id, evt) => {
                let cart_products = store.getters['products/cartProducts']
                const cartItem = cart_products.find(item => item.id === id)
                if(step===0.5 && (!/^(\d*)(\.)?([5])?$/.test(evt.target.value) && !/^[0-9]+$/.test(evt.target.value))) {
                    evt.target.value = cartItem.quantity
                    evt.preventDefault()
                }else if(step===1 && !/^[0-9]+$/.test(evt.target.value)) {
                    evt.target.value = cartItem.quantity
                    evt.preventDefault()
                }else if(evt.target.value===''){
                    store.dispatch('cart/changeProductToCart', {id:id, qty:0})
                    evt.preventDefault()
                }else{
                    if(!(step===0.5 && /^(\d*)(\.)?$/.test(evt.target.value))){
                        store.dispatch('cart/changeProductToCart', {id:id, qty:evt.target.value-(evt.target.value%step)})
                        evt.target.value = cartItem.quantity
                    }else if(step===0.5){
                        store.dispatch('cart/changeProductToCart', {id:id, qty:evt.target.value})
                        evt.target.value = cartItem.quantity
                    }
                    return true
                }
            }, (evt.target.value===''?3000:1000), step, id, evt)
        }
    }
}
