<template>
    <div>
        <v-app-bar :class="(top_logo)?`shadow_cast `:``+`app_bar mob d-print-none`"
        app :color="top_logo?'rgba(242,223,215,1)':'transparent'"
        >
            <v-btn fab depressed small color="white" @click="overlay_menu = !overlay_menu">
              <v-icon small>fas fa-bars</v-icon>
            </v-btn>
            <v-btn icon>
              <v-img :src="require(`@/assets/img/spacer.gif`)" height="26" width="26" contain></v-img>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title class="text-center">
              <router-link to="/" v-show="top_logo">
                <v-img :src="require(`@/assets/img/logo.png`)" max-height="30" contain></v-img>
              </router-link>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <router-link  v-if="cart_items.length" to="/cart">
              <v-btn fab depressed small color="white" class="mr-3">
                <v-badge class="note_badge"
                :color="(cart_items.length)?'secondary':'transparent'"
                left
                :content="cart_items.length"
                >
                  <v-icon small>fas fa-shopping-cart</v-icon>
                </v-badge>
              </v-btn>
            </router-link>
            <router-link  v-if="loggedIn" to="/orders">
              <v-btn icon class="mr-3">
                <v-badge class="note_badge"
                :color="(unseen_orders != 0)?'secondary':'transparent'"
                left
                :content="unseen_orders"
                >
                  <v-img :src="require(`@/assets/img/note_add.png`)" height="26" width="26" contain></v-img>
                </v-badge>
              </v-btn>
            </router-link>
            <router-link  v-if="loggedIn" to="/komunikacija">
              <v-btn icon class="mr-3">
                <v-badge class="note_badge"
                :color="(unseen.length)?'secondary':'transparent'"
                left
                :content="unseen.length"
                >
                  <v-img :src="require(`@/assets/img/send_white.png`)" height="26" width="26" contain></v-img>
                </v-badge>
              </v-btn>
            </router-link>
            <v-btn fab small depressed color="white" class="mr-3 d-print-none" v-if="loggedIn" @click="logout">
              <v-icon small>fas fa-power-off</v-icon>
            </v-btn>
            
            <v-btn icon v-else>
              <v-img :src="require(`@/assets/img/spacer.gif`)" height="26" width="26" contain></v-img>
            </v-btn>
        </v-app-bar>
        <v-app-bar class="app_bar desktop pt-1 d-print-none"
        app color="#f7f7f7" elevate-on-scroll height="106"
        >
          <v-container class="text-right desc_menu">
            <v-text-field
                v-model="article_search"
                label="Pretraži artikle"
                v-eager
                dense
                solo
                prepend-inner-icon="fas fa-search"
                class="search"
            ></v-text-field>
            <router-link to="/" class="desc_logo">
              <v-img :src="require(`@/assets/img/logo.png`)" max-height="100" width="100" contain></v-img>
            </router-link>
            <!--<v-btn icon v-if="route_name==='products'" @click.prevent="changeProductView()" class="left_btn desktop">
                <v-img :src="require(`@/assets/img/`+((product_view)?'one_row':'two_rows')+`.png`)" height="26" width="26" contain></v-img>
            </v-btn>-->
            <template
                v-for="icon in icons"
                class="mx-4 icon_holder"
                text
            >
                <router-link :to="icon.route" :key="icon.text" :class="((cart_items.length&&icon.route==='/cart')||(unseen_orders!=0&&icon.route==='/orders')||(unseen.length&&icon.route==='/komunikacija')?'have_some ':'')+'menu_link '+((route_name==icon.name)?` router_active`:``)">
                  <template v-if="icon.route==='/cart'">
                    <v-badge class="badge"
                    :color="(cart_items.length)?'secondary':'transparent'"
                    left
                    :content="cart_items.length"
                    >
                    </v-badge>
                  </template>
                  <template v-if="icon.route==='/komunikacija'">
                    <v-badge class="badge"
                    :color="(unseen.length)?'secondary':'transparent'"
                    left
                    :content="unseen.length"
                    >
                    </v-badge>
                  </template>
                  <template v-if="icon.route==='/orders'">
                    <v-badge class="badge"
                    :color="(unseen_orders!=0)?'secondary':'transparent'"
                    left
                    :content="unseen_orders"
                    >
                    </v-badge>
                  </template>
                  <span :class="`btn_text_menu`+((route_name==icon.name)?` secondary_color`:``)">{{ icon.text }}</span>
                </router-link>
            </template>
            <router-link  v-if="cart_items.length" to="/cart">
              <v-btn icon small :class="'mr-3 ml-5'+(cart_items.length?' have_some ':'')">
                <v-badge class="note_badge"
                :color="(cart_items.length)?'secondary':'transparent'"
                left
                :content="cart_items.length"
                >
                  <v-icon small>fas fa-shopping-cart</v-icon>
                </v-badge>
              </v-btn>
            </router-link>
            <v-btn icon small class="ml-4 d-print-none" v-if="loggedIn" @click="logout">
              <v-icon small>fas fa-power-off</v-icon>
            </v-btn>
          </v-container>
        </v-app-bar>
        <v-container class="mob kamen">
          <router-link to="/" class="kamen_logo">
              <div v-view="viewHandler" class="over_logo"></div>
              <v-img :src="require(`@/assets/img/logo.png`)" max-height="100" width="100" contain></v-img>
          </router-link>
          <v-text-field
            v-model="article_search"
            label="Pretraži artikle"
            v-eager
            dense
            solo
            prepend-inner-icon="fas fa-search"
            class="search"
          ></v-text-field>
        </v-container>
        <v-main
            class="mb-12 pb-5 min_height main_holder"
            fluid
        >
          <router-view :key="$route.path" />
        </v-main>
        <v-footer
          class="d-print-none theme--light"
        >
          <v-container>
            <v-row>
              <v-col lg="12" class="mt-8 text-center color_dark">
                2020 &copy; all rights reserved - v{{ver}}
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
        <v-dialog
          v-model="messageModal.modal_open"
          max-width="320"
        >
          <v-card>
            <v-card-title class="headline">{{ messageModal.title }}</v-card-title>
            <v-card-text max-height="300" v-html="messageModal.text">
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="messageModalClose"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="notification.modal_open"
          max-width="320"
        >
          <v-card>
            <v-card-title class="headline">Notifikacije</v-card-title>
            <v-card-text max-height="300">

                <p>Da li želite da uključite notifikacije?</p>
                <p>Notifikacije omogućavaju da budete obavešteni o novim pripremljenim porudžbinama, porukama i sl.</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="requestNotificationPermission"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="newVerModal.modal_open"
          max-width="320"
        >
          <v-card>
            <v-card-title class="headline">{{ newVerModal.title }}</v-card-title>
            <v-card-text max-height="300">
              <p class="text-center font12">Nova verzija aplikacije je dostupna. Možete rifrešovat browser ili kliknuti na Dugme "Update" dole ispod.</p>
              <p class="text-center"><span class="text-red">Ne updejtovanje može izazvati greške.</span></p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="green darken-1"
                text
                @click="newVerModalClose"
              >
                Close
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1 text-white"
                @click="reloadApp"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay :value="overlay_menu" class="kamen_holder">
            <v-btn class="kamen_menu"
              icon
              @click="overlay_menu = false"
            >
              <v-img :src="require(`@/assets/img/close.png`)" contain max-height="24" width="24" />
            </v-btn>
            <div class="ma-10 icons">
              <router-link
                v-for="icon in icons"
                :key="icon.text"
                :to="icon.route"
                :class="((route_name==icon.name)?`router_active`:``)+` icon_content`"
              >
                <v-btn
                    class="over_icon_holder"
                    @click="overlay_menu = false"
                >
                  <template v-if="icon.route==='/cart'">
                    <v-img :src="require(`@/assets/img/`+icon.icon+`.svg`)" contain />
                    <v-badge class="badge"
                      :color="(cart_items.length)?'secondary':'transparent'"
                      left
                      :content="cart_items.length"
                    >
                    </v-badge>
                  </template>
                  <template v-else-if="icon.route==='/komunikacija'">
                    <v-img :src="require(`@/assets/img/`+icon.icon+`.svg`)" contain />
                    <v-badge class="badge"
                      :color="(unseen.length)?'secondary':'transparent'"
                      left
                      :content="unseen.length"
                    >
                    </v-badge>
                  </template>
                  <template v-else-if="icon.route==='/orders'">
                    <v-img :src="require(`@/assets/img/`+icon.icon+`.svg`)" contain />
                    <v-badge class="badge"
                      :color="(unseen_orders!=0)?'secondary':'transparent'"
                      left
                      :content="unseen_orders"
                    >
                    </v-badge>
                  </template>
                  <v-img v-else :src="require(`@/assets/img/`+icon.icon+`.svg`)" contain />
                </v-btn>
                <span :class="`icon_text`+((route_name==icon.name)?` secondary_color`:``)">{{ icon.text }}</span>
              </router-link>
            </div>
        </v-overlay>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { versionReloadMixin } from '@/components/mixins/version-reload.mixin';
export default {
  props: {
    source: String,
  },
  mixins: [versionReloadMixin],
  computed:{ 
    ...mapGetters({
      cart_items: 'cart/cartProducts',
      notifications: 'messages/getMessages',
      search: 'getSearch',
      unseen: 'messages/getUnread',
      unseen_orders: 'orders/getUnseenOrders',
      messageModal: 'messageModal',
      newVerModal: 'newVerModal',
      ver: 'getVer',
      notification: 'notification',
      user_settings: 'user/getSettings',
      langs: 'data/getLangs',
      product_view: 'getProductView',
      loggedIn: 'user/loggedIn',
      icons: 'data/menu_icons',
      userCredentials: 'user/getCredentials'
    }),
    lang(){
      let lang = this.langs.filter((n) => n.id === this.user_settings.lang_id)
      return lang[0].symbol
    },
    menu_langs(){
      let langs = this.langs.filter((n) => n.id !== this.user_settings.lang_id)
      return langs
    },
    localAttrs(){
      const attrs = {}
      if (this.variant === 'default' || this.variant === 'exit') {
        attrs.absolute = false
        attrs.fixed = false
      } else {
        attrs[this.variant] = true
      }
      return attrs
    },
    route_name() {
      return this.$route.name
    },
    article_search: {
      set(article_search) {
          this.$store.commit('setArticleSearch', article_search)
      },
      get() {
          return this.$store.getters['getArticleSearch']
      }
    }
  },
  data: () => ({
    items: [
        'default',
        'absolute',
        'fixed',
    ],
    padless: true,
    variant: 'fixed',
    top_logo: false,
    overlay_menu: false
  }),
  methods:{
    openSearch(){
      this.$store.dispatch('toggle_search')
    },
    messageModalClose(){
      this.$store.commit('message_modal_close')
    },
    newVerModalClose(){
      this.$store.commit('newVer_modal_close')
    },
    changeProductView(){
      this.$store.dispatch('toggle_product_view')
    },
    changeValue(lang){
      this.$store.dispatch('user/change_settings', {'name':'lang_id', 'id':lang.id, 'refresh':true, 'evento':'page_layout'})
    },
    logout(){
      window.onbeforeunload = function () {
      }
      this.$store.dispatch('user/logout')
    },
    viewHandler(e){
      this.top_logo = (e.type!=='exit')?false:true
    },
    requestNotificationPermission() {
        // Check if the browser supports the Notification API
        if (!('Notification' in window)) {
            console.log('This browser does not support notifications.');
            localStorage.setItem('notificationPermission', 'denied');
            return;
        }
        
        Notification.requestPermission().then((permission) => {
            localStorage.setItem('notificationPermission', permission)
            this.$store.commit('notification_modal_close')
            if (permission === 'granted') {
                // Permission granted, you can now show notifications
                let text = this.notification.text!=''?this.notification.text:'Notifikacije su uključene.'
                new Notification(this.notification.title, { body: text })
            } else {
                // Permission denied or dismissed
                let text = this.notification.text!=''?this.notification.text:'Notifikacije su uključene.'
                this.$store.commit('message_modal', { title: this.notification.title, text: text }, { root: true })
                new Audio(require('@/assets/sounds/zapsplat_bell_service_desk_press_x2_18038.mp3')).play()
            }
        });
    }
  },
  watch: {
    article_search(newValue) {
      if(newValue!=''){
        if(this.$route.path!='/products/0')
          this.$router.push({ path: '/products/0' })
      }else{
        this.$router.push({ path: '/shop' })
      }
    },
  },
  beforeMount(){
    this.top_logo = true
  },
  mounted(){
    this.initVersionCheck(1000*15)//na 15 sec
    setTimeout(()=>{
      window.scroll({
        top: 1,
        left: 0,
        behavior: 'smooth'
      })
    },50)
    setTimeout(()=>{
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },100)
    setTimeout(()=>{
      var storedPermission = localStorage.getItem('notificationPermission');
      if(storedPermission==null){
        this.$store.dispatch('notification_modal',{modal_open:true, title:'Notifikacije', text:''})
      }
    },3000)
    var length = 6
    var hash = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        hash += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if(this.$store.getters['user/getCredentials'].reklamacije){
      let t = this
      // eslint-disable-next-line no-unused-vars
      let i = null;
      i = setInterval(
        (t,i,hash) => {
          if(!t.$store.getters['user/loggedIn']){
            clearInterval(i)
          }else{
            t.$store.commit('messages/setHash', hash)
            t.$store.dispatch('messages/getUnseenMessages', {
              hash
            })
            t.$store.dispatch('orders/getAllOrders', {
              hash
            })
          }
        },
        10*60*1000,/* na deset minuta */
        t,
        i,
        hash
      )
    }
  }
}
</script>
<style scoped>
  .search {
      position: absolute;
      bottom: 10px;
      right: 19px;
      border-radius: 22px;
      padding-left: 10px;
  }
  .desc_menu .search{
    bottom:-65px;
  }
  .desc_menu{
    position:relative;
  }
  .kamen{
    height:180px;
    background-image: url('/img/pecati.png'), url('/img/kamen.png');
    background-position: bottom center, bottom center;
    background-size:contain, 100%;
    background-repeat: no-repeat, no-repeat;
    position:relative;
  }
  .shadow_cast{
    box-shadow: 0 0 5px rgba(0,0,0,.2);
  }
  .kamen_logo{
    position:absolute;
    left:15px;
    bottom:-30px;
  }
  .over_logo{
    position: absolute;
    top:-70px;
    left:50%;
    height:1px;
    width:1px;
  }
  .kamen_menu{
    position:absolute;
    left:5px;
    top:5px;
  }
  .icons{
    position:relative
  }
  .have_some{
    position:relative;
  }
  .have_some:before{
    position:absolute;
    content:"";
    left:-8px;
    right:-8px;
    top:-3px;
    bottom:-3px;
    border-radius:20px;
    background: #FFF;
    z-index:-1;
  }
  .min_height{
    min-height:calc(100vh - 200px) !important
  }
  .desc_logo .v-image {
    margin-top: -25px;
  }
  .badge{
    position:absolute !important;
  }
  .color_dark{
    color:#545c71;
  }
  .menu_link{
    position:relative !important;
    margin-left:20px;
    width:auto !important;
    display:inline-block !important;
    padding-left:7px;
    padding-right:7px;
    line-height:30px !important;
    height:30px !important;
    text-align:center;
    color:#727C8E
  }
  .btn_lng{
    margin:0 0 0 15px
  }
  .btn_img_desc {
    position: absolute;
    left: 2px;
    top: 2px;
    height: 26px;
  }
  .v-menu__content{
    box-shadow: none;
  }
  .v-application .elevation-0{
    box-shadow:unset !important;
  }
  .v-btn--fab.v-size--small {
    height: 30px;
    width: 30px;
  }
  .theme--light.v-list{
    background-color: transparent;
  }
  .v-btn::before{
    background-color: transparent;
  }
  .v-list-item::before{
    background-color: transparent;
  }
  .footer .v-card__text{
    padding:16px 0 !important;
  }
  .over_icon_holder{
    border:#545c71 solid 1px;
    border-radius:50%;
    width:100%;
    padding-top:100% !important;
    position:relative;
    background-color: #F2DFD7 !important;
  }
  .icon_content{
      position:relative;
      display:inline-block;
      width:20%;
      margin:6.666666%;
  }
  .over_icon_holder .v-responsive {
      position: absolute;
      overflow: hidden;
      flex: 1 0 auto;
      width: 50% !important;
      left:25% !important;
      top:25% !important;
  }
  .footer .v-btn--icon {
    width: 60px !important
  }
  .text-white{
    color:#fff;
  }
  .text-red{
    color:rgb(192, 0, 0);
  }
  @media screen and (max-width: 370px) {
    .kamen{
      height:150px;
    }
    .icon_content{
      width:35%;
      margin:7.5%;
    }
    .icon_text{
      position:absolute;
      bottom:-1.6rem;
      font-size: .7rem;
    }
    .search.v-input{
      width:150px;
    }
  }
  @media screen and (min-width: 960px) {
    .main_holder{
      margin-top:60px;
    }
  }
  @media screen and (max-width: 960px) {
    .v-main{
      margin-top:-106px;
    }
  }
</style>