
  
  export function showNotification(notification) {
    if (Notification.permission === "granted") {
      new Notification(notification);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
            localStorage.setItem('notificationPermission', permission);
            new Notification(notification);
        }
      });
    }
  }