export const conf = Object.freeze({
    SHOP: '',
    WS: 'wss://bodirkovic.store:8080/ws',
    WS_CH: 'shop',
    TZ: "Europe/Zagreb",
    ENV: false,
    BASE_URL: '',
    VER: "1.2.12"
})
//"Europe/Zagreb"
  