import Vue from "vue"
import Router from "vue-router"
import PageLayout from './layouts/page_layout.vue'
import FormLayout from './layouts/form_layout.vue'
import store from './store'

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/products/:category(\\d+)",
      name: "products",
      props: true,
      component: () => import("./views/products.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/fast_order",
      name: "fast_order",
      component: () => import("./views/fast_order.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/shop",
      name: "shop",
      component: () => import("./views/shop.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/category/:category(\\d+)",
      name: "category",
      props: true,
      component: () => import("./views/category.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/cart",
      name: "cart",
      component: () => import("./views/cart.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/checkout",
      name: "checkout",
      component: () => import("./views/checkout.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/finish_order",
      name: "finish_order",
      component: () => import("./views/finish_order.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/successful_order",
      name: "successful_order",
      component: () => import("./views/successful_order.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
      meta: { layout: FormLayout }
    },
    {
      path: "/gen_pass/:hash",
      name: "gen_pass",
      props: true,
      redirect: { name: 'login' }
    },
    {
      path: "/ver_change/:hash",
      name: "ver_change",
      props: true,
      redirect: { name: 'login' }
    },
    {
      path: "/users",
      name: "users",
      component: () => import("./views/users.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/users_edit/:id(\\d+)",
      name: "users_edit",
      props:true,
      component: () => import("./views/users_edit.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/orders",
      name: "orders",
      component: () => import("./views/orders.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/order/:id(\\d+)",
      name: "order",
      props: true,
      component: () => import("./views/order.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/moje_kosarice",
      name: "moje_kosarice",
      component: () => import("./views/moje_kosarice.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/profil",
      name: "profil",
      component: () => import("./views/profil.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/komunikacija",
      name: "komunikacija",
      component: () => import("./views/komunikacija.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/message_add_form_order/:order_id(\\d+)/:item_id",
      name: "message_add_form_order",
      component: () => import("./views/message_add.vue"),
      props: true,
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/message_add/",
      name: "message_add",
      component: () => import("./views/message_add.vue"),
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/message_view/:id(\\d+)",
      name: "message_view",
      component: () => import("./views/message_view.vue"),
      props: true,
      meta: { layout: PageLayout, requiresAuth:true }
    },
    {
      path: "/change_password",
      name: "change_password",
      component: () => import("./views/change_password.vue"),
      meta: { layout: PageLayout, requiresAuth:true}
    },
    {
      path: "/recovery",
      name: "recovery",
      component: () => import("./views/forgot_pass.vue"),
      meta: { layout: FormLayout }
    },
    {
      path: "/change_email",
      name: "change_email",
      component: () => import("./views/change_email.vue"),
      meta: { layout: PageLayout, requiresAuth:true}
    },
    {
      path: "/not_auth",
      name: "not_auth",
      component: () => import("./views/NotAuth.vue"),
      meta: { layout: PageLayout, requiresAuth:true}
    },
    {
      path: "/404",
      alias: "*",
      name: "notFound",
      component: () => import("./views/NotFound.vue"),
      meta: { layout: PageLayout, requiresAuth:true}
    }
  ]
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.user.user.logged===0) {
      next({
        name: "login",
        query: { redirect: to.fullPath }
      });
    } else {
      next()
    }
  } else {
    next()
  }
});
export default router
