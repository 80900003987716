<template>
  <v-app>
    <div
        v-if="deferredPrompt"
        class="install primer"
    >
      <v-container>
        <v-row>
          <v-col cols="3" class="image_col">
            <div class="image_holder rounder white text-center">
              <v-img :src="require(`@/assets/img/install_logo.png`)" height="65" width="56" contain></v-img>
            </div>
          </v-col>
          <v-col cols="9">
            <div class="pa-4">
              Skinite Aplikaciju na svoj mobilni uređaj. Neće zauzimati mjesta na mobitelu!
            </div>
            <div class="bottom-install pa-4">
              <v-btn small class="float-left" text @click="dismiss">Odustani</v-btn>
              <v-btn small class="float-right text-white" color="black" @click="install">INSTALIRAJ</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <component :is="this.$route.meta.layout || 'div'" v-if="isOnline">
      <router-view />
    </component>
    <div class="text-center mt-8 pt-8" v-if="isOffline">
       <h1>Trenutačno ste offline,<br>rad sa aplikacijom nije moguć.</h1>
    </div>
  </v-app>
</template>

<script>
import Cookies from "js-cookie";
const debug = process.env.NODE_ENV !== 'production'
export default {
  name: 'App',
  strict: debug,
  data() {
    return {
      deferredPrompt: null
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault()
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    })
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null
    })
    window.addEventListener('beforeunload', () => {
      this.$store.commit('socket_isDisconnected')
      this.$store.commit('forms/setSubmitError', null)
      this.$store.commit('forms/setSubmitStatus', 'OK')
    })
  },
  methods: {
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 15 });
      this.deferredPrompt = null
    },
    async install() {
      this.deferredPrompt.prompt()
    }
  }
};
</script>
<style lang="scss" scoped>
  .image_holder{
    width:76px;
    margin-top:10px;
    padding:20px 10px;
    height:auto;
    position:absolute;
    left:50%;
    margin-left:-40px;
    box-shadow: 0 3px 5px rgba(0,0,0,.1);
  }
  .image_col{
    position:relative;
  }
  .install{
    position: fixed;
    bottom:0;
    width:100%;
    z-index:10000;
    border-radius: 10px 10px 0 0;
  }
  .text-white{
    color:#FFF !important
  }
</style>