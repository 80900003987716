// initial state
import { showNotification } from '../../sendNotification'
const state = {
  orders: [],
  order: {},
  unseenOrders: [],
  unseenOrdersNo: 0
}

// getters
const getters = {
  getOrders: (state) => {
    return state.orders
  },
  getUnseenOrders: (state) => {
    return state.unseenOrdersNo
  },
  orderDetails(state){
    let order = state.order
    if(typeof order.message_id == 'undefined') order.message_id = 0
    if(typeof order.order_items !== 'undefined'){
      order.products = order.order_items.map((order_item) => {
        let total = order_item.parent_price * order_item.qty
        if(typeof order_item.message_id == 'undefined') order_item.message_id = 0
        return {
          ...order_item,
          total 
        }
      })
      return order
    }else{
      order.products = []
      return order
    }
    
  }
}

// actions
const actions = {
  getAllOrders ({ dispatch, rootGetters }, date=null) {
    let user = rootGetters['user/getUser']
    dispatch('emit', {
      end: "orders/setOrders",
      call: "getOrders",
      parameters:{
        user_id: user.id,
        date: date
      },
      response:"response"
    }, { root: true })
  },
  getUnseenOrders ({ dispatch, rootGetters }, date=null) {
    let user = rootGetters['user/getUser']
    dispatch('emit', {
      end: "orders/setUnseenOrders",
      call: "getOrders",
      parameters:{
        user_id: user.id,
        date: date
      },
      response:"response"
    }, { root: true })
  },
  setUnseenOrders ({commit, dispatch}, data) {
    let results = JSON.parse(data.response);
    if(results.length>0){
      if(state.unseenOrders.length==0){
        results.forEach((result) => {
          commit('setUnseenOrders', result.id)
        })
      }else{
        let unssenOrders = state.unseenOrdersNo
        results.forEach((result) => {
          let id  = state.unseenOrders.find((order) => order.id === result.id)
          if(id === undefined && result.ord_status_id==2){
            commit('setUnseenOrders', result.id)
            unssenOrders++
          }
        })
        if(unssenOrders>0){
          commit('set_unseen_no', unssenOrders)
          var storedPermission = localStorage.getItem('notificationPermission');
          if(storedPermission === null){
            dispatch('notification_modal', { modal_open: true, title: 'Nova narudžba', text: 'Imate novu narudžbu u arhivi. Molimo proverite količine koje će biti isporučene.' }, { root: true })
          }else if(storedPermission === 'granted'){
            showNotification('Imate novu narudžbu u arhivi. Molimo proverite količine koje će biti isporučene.')
          }else if(storedPermission === 'denied') {
            commit('message_modal', { title: 'Nova narudžba', text: 'Imate novu narudžbu u arhivi. Molimo proverite količine koje će biti isporučene.' }, { root: true })
            new Audio(require('@/assets/sounds/zapsplat_bell_service_desk_press_x2_18038.mp3')).play()
          }
        }
      }
    }
  },
  getOrderDetails ({ dispatch }, order_id) {
    dispatch('emit', {
      end: "orders/setOrder",
      call: "getOrderById",
      parameters:{
        order_id: order_id
      },
      response:"response"
    }, { root: true })
  },
  setOrder ({commit}, data) {
      commit('setOrder', JSON.parse(data.response))
  },
  changeOrderDate({ dispatch, state}, payload) {
    console.log(state.order.date_delivery+' '+payload.new_date)
    if(state.order.date_delivery!=payload.new_date){
      dispatch('emit', {
        end: "orders/updateOrderDate",
        call: "a_cancelOrder",
        parameters:{order_id: payload.order_id, status: 1, napomena: 'date*'+payload.new_date},
        response:"{\"status\": 200}"//samo status 200 ili neki drugi ako nije uspeo
      }, { root: true })
    }
  },
  updateOrderDate ({commit}, data) {
    let res = JSON.parse(data.response)
    if(res.status===200){
      let new_date = data.parameters.napomena.split('*')[1]
      commit('updateOrderDate', { date: new_date})
      commit('message_modal', { title: 'Uspjeh', text: 'Uspešno promenjen datum narudžbe.' }, { root: true })
    }//todo error message
  },
  setOrders ({commit}, data) {
      commit('setOrders', JSON.parse(data.response))
  },
  hideOrder({ dispatch }, payload) {
    dispatch('emit', {
      end: "orders/getAllOrders",
      call: "a_cancelOrder",
      parameters:{order_id:payload.id, status:0, napomena:'order_hide'},
      response:"{\"status\":200}"//samo status 200 ili neki drugi ako nije uspeo
    }, { root: true })
  },
  setOrderStatus({commit}, data){
    let res = JSON.parse(data.response)
    if(res.status!=200){
      Error('Something went wrong')// todo zameniti sa statusnim porukama
    }else{
      commit('setOrderStatus', res)
    }
  }
}

// mutations
const mutations = {
  setOrders (state, data) {
      state.orders = data
      state.unseenOrdersNo = 0
  },
  set_unseen_no(state, data){
    state.unseenOrdersNo = data
  },
  setOrder (state, data) {
    state.order = data
  },
  updateOrderDate(state, data){
    state.order.date_delivery = data.date
    //state.orders = [...state.orders.filter(o => o.id !== parseInt(order.id)), order]
  },
  setOrderStatus(state, data){
    let order = state.orders.find(ord => ord.id === data.parameters.order_id)
    order.status = data.parameters.ord_status_id
    state.orders = [...state.orders.filter(o => o.id !== parseInt(order.id)), order]
  },
  setUnseenOrders (state, data) {
      state.unseenOrders = [...state.orders, data];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
